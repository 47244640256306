.align-center {
  text-align: center;
}

.row {
  margin-bottom: 15px;
  clear: both;
}

nav {
  background-image: url("../../assets/images/customer-register/left-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 35px;
  float: left;
  margin-right: 3px;
  position: relative;
  left: 0px;
  z-index: 9999;
  height: 18px;
  margin-top: 7px;
}

#page-title {
  font-size: 22px;
  margin-left: 50px;
  font-family: "Montserrat-Bold";
}

.main-container {
  padding: 5px 35px;
  margin-bottom: 70px;
}

div#header {
  margin-bottom: 40px;
}

.full-width {
  width: 100%;
  float: left;
}

section {
  margin: 10px 0px 20px 0px;
}

/*Temporary*/
.btn-done {
  width: 100%;
  background-color: rgba(125, 167, 75, 1) !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-family: "Montserrat-Bold" !important;
  font-size: 14px !important;
  margin-top: 80px !important;
}

.btn-skip {
  width: 100%;
  border-radius: 30px !important;
  font-family: "Montserrat-Bold" !important;
  font-size: 14px !important;
  margin-bottom: 8px !important;
}

#participant-content-area {
  height: 60vh;
  overflow-y: scroll;
  position: relative;
}

.loaderOuter {
  background-color: #fffafc;
  height: 800px;
  text-align: center;
}

.loaderImage {
  margin-top: 70%;
  width: 80px;
  /* margin: 0 auto; */
}

.loaderText {
  font-family: "Montserrat-SemiBold";
  font-size: 15px;
  text-align: center;
  width: 100%;
  color: #0a0819;
  padding: 0px 10px;
}
