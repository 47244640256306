.Buttons{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 99999;
}
.zoomBtn{
    bottom: 50px;
}
.imagetemplate{
    width: 100%;
    position: relative;
    z-index: 9;
    /* display: none; */
    
}