header{
   border-bottom:3px solid #e7e8e8;
   padding: 20px;
   background: linear-gradient(#9DBB2C,#7DA74B);
   color: #fff;
}
h6{
    margin: 0;
    /* margin-left: 10px; */
    font-size: 14px;
}
header div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.serch-header{
    padding: 10px;
    padding-left:20px;
   
}

.serch-header div{
    /* justify-content: start; */
   
}
.search-wraper{
    width: 85%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.search-suggession{
    display: flex;
    flex-direction: column;
    background-color:#fff;
    width: 75%;
   align-items: flex-start;
   padding-left: 20px;
   padding-right: 20px;

   position:absolute;
   padding-top: 15px;
   top:40px;
   z-index:999;
   color: #000;
   border-radius: 10px;
   -webkit-box-shadow: -1px 1px 12px -4px rgba(0,0,0,0.75);
   -moz-box-shadow: -1px 1px 12px -4px rgba(0,0,0,0.75);
   box-shadow: -1px 1px 12px -4px rgba(0,0,0,0.75);
}
.search-suggession span{
    color: #000;
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    opacity: 0.8;
    text-decoration: none;
}
.search-suggession span:hover{
    border-bottom-color:#9DBB2C ;
}
/* .search-suggession span:hover{
background-color: red;
} */
.search-bar{
    width: 80%;
    height: 40px;
    border: 0;
    border-radius: 20px;
    margin-left: 20px;
    padding-left: 20px;
  
}
