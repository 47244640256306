.btnMenuDrawer {
  position: relative;
  left: -50px;
}
a.meni-item-link {
  text-decoration: none;
  color: #5a4a4ade;
  text-transform: capitalize;
  width: 100%;
}

.menuIcon {
  position: absolute;
  z-index: 1;
  left: 0px;
  width: 17px;
}
.menu-user-img img{
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.profile-section{
  padding: 20px;
  display: flex;
  align-items: center;
}
.user-text-container{
  padding-left: 10px;
}
.user-text-container span{
  font-size: 12px;
  width: 95%;
  word-break: break-all;
}