.privacyGreenBlock {
  background-image: linear-gradient(to right, #9dbb2c, #7da74b);
  padding: 20px;
}

.ppOuter {
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.144);
  border: 0.5px solid rgba(255, 255, 255, 0.144);
  border-radius: 8px;
}

.ppTitle {
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.03px;
  margin: 0px;
}

span#username {
  font-size: 1.5em;
}

.headerTitle {
  text-align: center;
  font-size: 17px;
  color: #fff;
  margin-top: 0px;
  font-family: "Montserrat-Bold";
}

.pageOuter {
  min-height: 100vh;
  background-color: #f6f6f6;
}

.orderListImage {
  /* width: 100%; */
  border-radius: 5px;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.orderSummaryOuter {
  padding: 15px !important;
}

.imagePrice {
  font-family: "Montserrat-Bold";
  font-size: 20px;
  vertical-align: middle;
  text-align: left;
}

.imageDeleteIcon {
  font-family: "Montserrat-Bold";
  font-size: 22px;
  color: #d63031;
}

.imageOuterGrid {
  border-bottom: 2px dashed #ddd;
  margin-top: 10px !important;
}

.columnGrid {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.backBtn {
  color: #fff;
  position: absolute;
  font-size: 25px;
  cursor: pointer;
}

.summaryHeaderOuter {
  position: relative;
  padding: 20px;
}
