.register-form{
    padding: 30px 20px;
    position: relative;
    height: 90vh;
}
.register-form .header{
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.register-form .header h4{
    font-size: 18px;
    color: #0A0819;
    font-weight: bold;
    margin-left: 20px;
    /* margin-bottom: 0; */
}
.register-form form{
    padding-top: 20px;
}
.register-form .form-control{
    margin-bottom: 20px;
    position: relative;
    display: flex;

}
.register-form .error{
    border: 1px solid red !important;
}
.register-form .form-control input,select{
    background-color: #EFEFEF;
    height: 50px;
    border:0;
    border-radius: 25px;
    width: 90%;
    padding-left: 30px;
    font-size: 12px;
   
}
.register-form .form-control span{
    font-size: 10px;
    font-weight: 600;
    left: 30px;
    /* padding-left: 30px; */
    color: #FF9494;
    position: absolute;
    bottom: -14px;
}
.register-form .form-control select{
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    color: #A2A2A2;
    font-size: 12px;
}
.register-form .form-control input::placeholder{
    color: #A2A2A2;
  font-size: 12px;
}

.register-form .form-element p{
    font-size: 14px;
    font-weight: bold;
    color: #0A0819;
    padding-bottom: 10px;
}
.register-form button{
    height: 50px;
    border-radius: 25px;
    width: 90%;
    background: linear-gradient(#9DBB2C,#7DA74B);
    border: 0;
    color: #FFFFFF;
    font-size: 14px;
    text-transform: uppercase;
    position:absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.register-form .select-form > div > div:not(#react-select-3-listbox){
    background: #EFEFEF;
    height: 50px;
    border: 0;
    border-radius: 25px;
    padding-left: 20px;
    font-size: 14px;   
}

.register-form .select-error > div > div{
    border: 1px solid red !important;
}
.register-form .select-error {
    position: relative;
}
.register-form .select-error-span{
    font-size: 10px;
    font-weight: 600;
    left: 30px;
    /* padding-left: 30px; */
    color: #FF9494;
    /* position: absolute; */
    /* bottom: -14px; */
}


/* Image Upload Style */
.image-upload{
    position: relative;
    height: 95vh;
    padding-right: 10px;
    padding-left: 10px;
 
}
.image-upload .wraper{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 220px;
    padding-top: 30px;
    justify-content: space-between;
}
.image-upload p{
    font-size: 12px;
    padding-top: 20px;
    opacity: 0.6;
}
.image-upload li{
    font-size: 12px;
    font-weight: 600;
}
.image-upload .wraper button{
    width: 40%;
    position: relative;
}

.profile-image{   
    display: flex;
    justify-content: center;
   
}
button[disabled]{
    background: #dddddd ;
} 
.profile-image img{
    width: 100px;
    /* border: 2px solid red; */
    height: 100px;
    box-shadow: #0A0819;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px;
    /* height: 100px; */
}

.profile img{
    box-shadow: 0px 0px 0px;
    border-radius: 80px;
    width: 90px;
    height: 90px;
    padding: 0px;
    background: linear-gradient(#9DBB2C,#7DA74B);


}

.save-btn{
    position: relative !important;
    margin-top: 50px;
    width: 100% !important;
}