.tab-bar{
    display: flex;
    justify-content: space-between;
    background: #F6F6F6;
   
}
.tab-bar .active{
    border-bottom: 2px solid #7DA74B;
}
.tab-bar div{
    text-align: center;
    width: 100%;
}
.tab-bar p{
    font-weight: 600;
    font-size: 16px;
    color: #0A0819;
}
.list-group{
    padding-top: 30px;
    padding-left: 10px;
}
.list-group-vertical{
    height: 500px;
    overflow-y: scroll;
}
.rounded{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    /* box-shadow: 0px 0px 5px; */
}

.company-details{
    display: flex;
    align-items: center;
    /* background-color: red; */
}
.company-details h5{
    padding-left: 5px;
}
/* .users-list{
    width: 100%;
    display: flex;
    height: 150px !important;

} */
.img-box{
    position: relative;
    /* flex-basis: 49%; */
    align-self: flex-start;
    padding-bottom: 2%;
    width: 100%;
}

.img-box img{
    height: 100%;
    width: 100%;
    border-radius: 6px;
}

.user-text-wraper{
    color: #F6F6F6;
    font-size: 12px;
    font-weight: 600;
    box-sizing: border-box;
    background: #000;
    position: absolute;
    width: 100%;
    padding: 6px 5px;
    /* text-align: center; */
    padding-left: 10px;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
}
.list-group-vertical{
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 6%;
}
