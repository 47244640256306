.greenBlock {
  height: auto;
  background-image: linear-gradient(to right, #9dbb2c, #7da74b);
  padding: 30px;
}

.eventDetailsOuter {
  padding: 25px;
  background-color: hsla(0, 0%, 100%, 0.144);
  border: 0.5px solid hsla(0, 0%, 100%, 0.144);
  border-radius: 8px;
  min-height: 37vh;
  margin-top: 12px;
}

.eventTitle {
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.03px;
  margin: 0px;
  font-family: "Montserrat-SemiBold";
}

.eventDetails {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.02px;
  margin-top: 20px;
  font-family: "Montserrat-Regular";
}

.participateBtn {
  width: 155px;
  padding: 13px 0px;
  border-radius: 24px;
  background-color: #fff !important;
  box-shadow: 0px 4px 10px #00000029;
  float: right;
  margin-top: 30px !important;
  border: none;
  color: #85ac43 !important;
  font-size: 12px !important;
  font-family: "Montserrat-Bold" !important;
  text-transform: capitalize !important;
}

.headerTitleHome {
  text-align: left !important;
  font-size: 17px;
  color: #fff;
  margin-top: 0px;
  font-family: "Montserrat-Bold";
}

.pageOuter {
  min-height: 100vh;
  background-color: #f6f6f6;
}

.participantHeading {
  background-color: #fff;
  padding: 10px 15px;
}

.participantHeadingTxt {
  font-size: 14px;
  letter-spacing: 0.03px;
  color: #0a0819;
  font-family: "Montserrat-Bold";
}

.participantCount {
  font-size: 12px;
  letter-spacing: 0.02px;
  color: #ff316a;
  float: right;
  font-family: "Montserrat-Bold";
  margin-top: 8px;
}

.usericon {
  width: 13px;
}

.userIconOuter {
  background-color: #ff316a;
  margin-right: 10px;
  padding: 5px;
  border-radius: 50%;
}

.clr {
  clear: both;
}

.eventImgOuter {
  text-align: center;
}

.eventImg {
  max-width: 100px;
}

/* Participant list */

.participantListNew {
  /* padding: 10px 15px; */
  background-color: #fff;
  border-bottom: 1px solid #888c8d;
  height: 90px;
  width: 100%;
  padding-top: 10px;
}

.width-80 {
  width: 80%;
  float: left;
  margin-top: 15px;
}

.nameDiv {
  width: 70%;
  float: left;
  padding-left: 5px;
}

.ageDiv {
  width: 20%;
  float: right;
  /* padding-right: 20px; */
}

.districtDiv {
  width: 35%;
  float: right;
  /* padding-right: 20px; */
  padding-left: 5px;
}

.schoolDiv {
  width: 60%;
  float: left;
}

.infoTextName {
  text-align: left;
  color: #000;
  font-size: 17px;
  font-family: "Montserrat-Bold";
  letter-spacing: 0.26px;
  margin: 0;
}

.infoText {
  text-align: left;
  color: #000;
  font-size: 14px;
  font-family: "Montserrat-SemiBold";
  margin: 0;
}

.infoTextRight {
  text-align: right;
  color: #000;
  font-size: 12px;
  font-family: "Montserrat-SemiBold";
  margin: 0;
  padding-right: 8px;
}

.userProfileImageDiv {
  width: 70px;
  height: 80px;
  /* background-color: red; */
  float: left;
}

.userProfileImage {
  width: 100%;
  object-fit: cover;
  /* border-radius: 50%; */

  /* margin-top: 22px; */
  /* margin-right: 5px; */
}

.infoIcons {
  vertical-align: bottom;
  color: rgba(75, 75, 75, 0.795);
  margin-right: 5px;
  font-size: 1.2rem !important;
}

.loaderOuter {
  background-color: #fffafc;
  height: 800px;
  text-align: center;
}

.loaderImage {
  margin-top: 70%;
  width: 80px;
  /* margin: 0 auto; */
}

.loaderText {
  font-family: "Montserrat-SemiBold";
  font-size: 15px;
  text-align: center;
  width: 100%;
  color: #0a0819;
}

.eventAdvertText {
  font-size: 10px;
  color: #fff;
  letter-spacing: 0.02px;
  font-family: "Montserrat-Medium";
  line-height: 19px;
  text-align: center;
  position: absolute;
  bottom: -36px;
  right: 0;
  margin-left: 10px;
  margin-right: 10px;
}
