.btnMenuDrawer {
  position: relative;
  left: -50px;
}
a.meni-item-link {
  text-decoration: none;
  color: #5a4a4ade;
  text-transform: capitalize;
  width: 100%;
}

.menuIcon {
  position: absolute;
  z-index: 1;
  left: 0px;
  width: 17px;
}

.menu-btn{
  color: #fff;
  font-size: 20px;
}
.support-contact{
  position: absolute;
  bottom: 0;
  font-size: 14px;
  padding-left: 10px; 
  padding-bottom: 40px;
}