.counterOuter{
    width: 100%;  
    display: inline-block;  
    text-align: center;
}

.minusBtn, .plusBtn{
    width: 50px;
    height: 50px;
    background-color: rgba(125, 167, 75, 1);    
    font-size: 18px;   
    color: #fff;
    /* float: left; */
    border: 1px solid #ffff;
    box-shadow: 2px 2px 5px #e1e1e1;
    border-radius: 50%;
    margin: 0 10px;
}

.counterVal{
    width: 100px;
    height: 30px;
    text-align: center;
    /* float: left; */
    font-family: "Montserrat-Medium";
    font-size: 20px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #cdcdcd;
}

.inactive{
    opacity: 0.5;
}

.counterIcon{
    vertical-align: middle;
}