.errorPageOuter {
  min-height: 100vh;
  /* background-image: linear-gradient(to right, #9dbb2c, #7da74b); */
  /* background-color: #f0fdfe; */
  text-align: center;
}

.errorImg {
  width: 85%;
  margin-top: 15%;
  margin-bottom: 60px;
}

.errorMsgTitle {
  color: #000;
  font-size: 22px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-family: "Montserrat-Bold";
}

.errorMsg {
  color: #000;
  font-size: 18px;
  margin-top: 0px;
  font-family: "Montserrat-SemiBold";
}

.homeBtn {
  /* background-image: linear-gradient(to right, #9dbb2c, #7da74b) !important; */
  width: 60%;
  margin-top: 20px !important;
  background-color: #fff !important;
  color: #000 !important;
  font-family: "Montserrat-Bold" !important;
  border: 1px solid #119ba5 !important;
}
