.campaigns-items .item {
  width: 80%;
  margin: auto;
  margin-bottom: 15px;
}

.campaignLoader {
  width: 100%;
  text-align: center;
}

.campLoadingImg {
  width: 40px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  vertical-align: middle;
}

.campLoadingTxt {
  font-size: 12px;
  color: #7da74b;
  letter-spacing: 0.02px;
  vertical-align: middle;
  font-family: "Montserrat-Bold" !important;
}

.loadBtn {
  color: #fff !important;
  background-color: #7da74b !important;
  font-size: 11px !important ;
  font-family: "Montserrat-Bold" !important;
  letter-spacing: 0.02px !important;
  text-transform: capitalize;
  width: 150px;
}

.loadBtnOuter {
  text-align: center;
  padding-bottom: 10px;
}

.campaigns-items .item img {
  width: 100%;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* 
@media (min-width: 961px) {
  
  .campaigns-items .item {
    width: 200px !important;
    margin: 15px !important;
    margin-bottom: 15px !important;
    float: left !important;
  }

  body {
    max-width: 100%;
  }
} */
