.nextIcon {
  vertical-align: middle;
  margin-left: 5px;
}

.itemCount {
  font-family: "Montserrat-Regular";
  color: #fff;
  font-size: 12px;
  text-transform: none;
  padding: 0;
  margin: 0;
}

.totalPrice {
  font-family: "Montserrat-Bold";
  color: #fff;
  font-size: 14px;
  text-transform: none;
  padding: 0;
  margin: 0;
}

.buttonEndText {
  font-family: "Montserrat-Bold";
  color: #fff;
  font-size: 14px;
  text-transform: none;
}

.buyPhotoBtn {
  width: 100%;
  background-color: rgba(125, 167, 75, 1) !important;
  color: #fff !important;
  /* border-radius: 30px !important; */
  font-family: "Montserrat-Bold" !important;
  font-size: 14px !important;
  max-width: 500px;
  height: 50px;
}

.buyPhotoOuter {
  position: fixed !important;
  z-index: 999 !important;
  bottom: 0;
  width: 100%;
  /* overflow-y: hidden; */
  background-color: #fff;
}

.buyInfo {
  max-width: 100%;
  background-color: #fff;
  overflow: inherit !important;
}

.swipeUpImg {
  width: 110px;
  display: flex;
  margin: 0 auto;
}

.buyInfoContent {
  padding: 8px;
  padding-top: 20px;
}

.closeOuter {
  margin-top: 80px;
}

.conditionTitle {
  font-size: 14px;
  color: #000;
  margin-top: 65px;
  font-family: "Montserrat-Medium";
}

.conditionTxt {
  font-size: 12px;
  color: #000;
  margin-top: 0px;
  font-family: "Montserrat-Regular";
}

.closeIcon {
  vertical-align: middle;
}

.closeLink {
  vertical-align: middle;
}

.swipeTxt {
  font-size: 10px;
  color: rgba(125, 167, 75, 1);
  margin-top: 0px;
  font-family: "Montserrat-SemiBold";
  padding: 5px;

  margin-top: 15px;
  letter-spacing: 0.6px;
  text-align: center;
}

.clickableArea {
  padding: 8px;
}
