.backBtn {
  color: #fff;
  position: absolute;
  font-size: 25px;
  cursor: pointer;
}

.headerRegisteredOuter {
  position: relative;
  padding: 20px;
}

.headerRegisteredTitle {
  text-align: center;
  font-size: 17px;
  color: #fff;
  margin-top: 0px;
  font-family: "Montserrat-Bold";
}

.postOuter {
  min-height: 100vh;
  background-image: linear-gradient(to right, #9dbb2c, #7da74b);
  padding-bottom: 50px !important;
}

.eventRegisteredOuter {
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.144);
  border: 0.5px solid rgba(255, 255, 255, 0.144);
  border-radius: 8px;
  min-height: max-content;
  margin-left: 30px;
  margin-right: 30px;
  position: relative;
}

.eventRegisteredTitle {
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.03px;
  margin: 0px;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 13px;
}

.eventRegisteredDetails {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.02px;
  margin-top: 10px;
  font-family: "Montserrat-Regular";
  line-height: 16px;
  font-weight: 400;
  /* margin-bottom: 50px; */
}

.createPostSection {
  /* padding: 20px; */
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.createPostLabel {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.02px;
  font-family: "Montserrat-Regular";
}

.hiddenSection {
  display: none;
}

.clearBoth {
  clear: both !important;
}

.advertText {
  font-size: 10px;
  color: #fff;
  letter-spacing: 0.02px;
  font-family: "Montserrat-Medium";
  line-height: 19px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-left: auto;
  margin-right: 12px;
}
