.campaigns-items .item {
  width: 80%;
  margin: auto;
  margin-bottom: 15px;
  position: relative;
}

.hiddenCanvas {
  display: none;
}

.downloadFb {
  position: absolute !important;
  right: 0 !important;
  background: #6998e6 !important;
  padding: 7px 14px;
  border-radius: 0 0 0 12px;
  cursor: pointer;
  box-shadow: -3px 4px 4px 0px #141414c7;
  border: 1px solid #fbf5f52b;
}

.exportIcon {
  transform: rotate(180deg);
}

.campaigns-items .item img {
  width: 100%;
}
