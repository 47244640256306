.participantListNew {
  /* padding: 10px 15px; */
  background-color: #fff;
  border-bottom: 1px solid #888c8d;
  height: 90px;
  width: 100%;
  padding-top: 10px;
}

.width-80 {
  width: 80%;
  float: left;
  margin-top: 15px;
}

.nameDiv {
  width: 70%;
  float: left;
  padding-left: 5px;
}

.ageDiv {
  width: 20%;
  float: right;
  /* padding-right: 20px; */
}

.districtDiv {
  width: 35%;
  float: right;
  /* padding-right: 20px; */
  padding-left: 5px;
}

.schoolDiv {
  width: 60%;
  float: left;
}

.infoTextName {
  text-align: left;
  color: #000;
  font-size: 17px;
  font-family: "Montserrat-Bold";
  letter-spacing: 0.26px;
  margin: 0;
}

.infoText {
  text-align: left;
  color: #000;
  font-size: 14px;
  font-family: "Montserrat-SemiBold";
  margin: 0;
}

.infoTextRight {
  text-align: right;
  color: #000;
  font-size: 12px;
  font-family: "Montserrat-SemiBold";
  margin: 0;
  padding-right: 8px;
}

.userProfileImageDiv {
  width: 70px;
  height: 80px;
  /* background-color: red; */
  float: left;
}

.userProfileImage {
  width: 100%;
  object-fit: cover;
  /* border-radius: 50%; */

  /* margin-top: 22px; */
  /* margin-right: 5px; */
}

.infoIcons {
  vertical-align: bottom;
  color: rgba(75, 75, 75, 0.795);
  margin-right: 5px;
  font-size: 1.2rem !important;
}
