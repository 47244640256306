.profileGreenBlock {
  background-image: linear-gradient(to right, #9dbb2c, #7da74b);
  padding: 30px;
}

.profileOuter {
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.144);
  border: 0.5px solid rgba(255, 255, 255, 0.144);
  border-radius: 8px;
}

.profileTitle {
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.03px;
  margin: 0px;
  font-family: "Montserrat-SemiBold";
}

.profileTitleWelcome {
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.03px;
  margin: 0px;
  font-family: "Montserrat-Regular";
}

span#username {
  font-size: 1.5em;
}

.eventDetails {
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.02px;
  margin-top: 10px;
}

.participateBtn {
  width: 155px;
  padding: 13px 0px;
  border-radius: 24px;
  background-color: #fff !important;
  box-shadow: 0px 4px 10px #00000029;
  float: right;
  margin-top: 20px !important;
  border: none;
  color: #85ac43 !important;
  font-size: 12px !important;
}

.headerTitle {
  text-align: center;
  font-size: 17px;
  color: #fff;
  margin-top: 0px;
  font-family: "Montserrat-Bold";
}

.pageOuter {
  min-height: 100vh;
  background-color: #f6f6f6;
}

.campaignPostHeading {
  background-color: #fff;
  padding: 10px 15px;
}

.campaignHeadingTxt {
  font-size: 14px;
  letter-spacing: 0.03px;
  color: #0a0819;
}

.campaignPostCount {
  font-size: 12px;
  letter-spacing: 0.02px;
  color: #ff316a;
  float: right;
  margin-top: 6px;
}

.usericon {
  width: 13px;
}

.userIconOuter {
  background-color: #ff316a;
  margin-right: 10px;
  padding: 5px;
  border-radius: 50%;
}
.page-wrapper{
  padding-left: 20px;
  padding-right:20px;
}
.page-wrapper p{
  opacity: 0.5;
  font-size: 14px;
  text-align: center;
  line-height: 10px;
}
.user-profile-image{
  height: 60px;
  width: 60px;
  border-radius: 30px;
  margin-right: 15px;
}
.edit-icon{
  color: #fff;
  position: absolute;
  bottom: 10px;
  right: 15px;
}
.profile-wrapper{
  display: flex;
  align-items: center;
}

/* @media (min-width: 961px) {
  body {
    max-width: 500px;
  }
} */
